<template>
  <div class="service-data-statistics" ref="serviceDataStatistics">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <analysisForm :chooseToday="true" @getFormVal="onSubmit">
        <el-button
          @click="getXLS"
          type="primary"
          size="small"
          :disabled="tableData.length === 0"
          :loading="downLoadStatus"
          >导出</el-button
        >
      </analysisForm>
    </div>
    <el-table
      id="outputTable"
      :data="tableData"
      :height="tableHeight"
      @cell-click="handleClick"
    >
      <el-table-column type="index" label="序号">
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="所属车队"
        :show-overflow-tooltip="true"
        :formatter="formatNull"
      ></el-table-column>
      <el-table-column
        label="车牌号"
        width="180"
        prop="cph"
        :show-overflow-tooltip="true"
        :formatter="formatNull"
      >
      </el-table-column>
      <el-table-column
        prop="runTimeStr"
        label="运营时长（小时.分）"
        width="220"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="operatingAmount"
        label="营收金额（元）"
        :formatter="formatNull"
      >
        <template slot-scope="scope">
          <span :class="{ active: scope.row.index !== 1 }">{{
            formatMoney(scope.row.operatingAmount)
          }}</span>
        </template></el-table-column
      >
      <el-table-column
        prop="signInNum"
        label="登签次数"
        :formatter="formatNull"
      >
        <template slot-scope="scope">
          <span :class="{ active: scope.row.index !== 1 }">{{
            scope.row.signInNum || "-"
          }}</span>
        </template></el-table-column
      >
      <el-table-column
        prop="signOutNum"
        label="退签次数"
        :formatter="formatNull"
      >
        <template slot-scope="scope">
          <span :class="{ active: scope.row.index !== 1 }">{{
            scope.row.signOutNum || "-"
          }}</span>
        </template></el-table-column
      >
      <el-table-column prop="score" label="评价分数"></el-table-column>
      <el-table-column prop="complaintNum" label="投诉次数"></el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 营运详情 -->
    <el-dialog
      title="营运详情"
      width="60%"
      top="10vh"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
    >
      <div>
        <serviceEvaluation class="body" :params="params"></serviceEvaluation>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="dialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 投诉详情 -->
    <el-dialog
      title="投诉详情"
      width="70%"
      top="10vh"
      :visible.sync="complaintVisible"
      v-if="complaintVisible"
    >
      <div>
        <complaint class="body" :params="params"></complaint>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="complaintVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="complaintVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 登签详情 -->
    <el-dialog
      title="登签退签详情"
      width="40%"
      top="10vh"
      :visible.sync="signVisible"
      v-if="signVisible"
    >
      <div>
        <sign class="body" :params="params"></sign>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="signVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="signVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import analysisForm from '@/components/analysisForm/analysisForm.vue'
import serviceEvaluation from './components/serviceEvaluation.vue'
import complaint from './components/complaint.vue'
import sign from './components/sign.vue'
import { YHTools } from '@/assets/js/Tools.js'
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { getCurent } from '@/common/utils/index'

import { queryVehicleDataByList } from '@/api/lib/taxi-api.js'
export default {
  name: 'serviceDataStatistics',
  components: {
    analysisForm,
    serviceEvaluation,
    complaint,
    sign
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10
      },
      exportForm: {},
      downLoadStatus:false,
      total: 0,
      dialogVisible: false,
      complaintVisible: false,
      signVisible: false,
      params: null,
      columns: ['vehicleGroupName', 'cph', 'runTime', 'operatingAmount', 'signInNum', 'signOutNum']
    }
  },
  methods: {
    /** 导出 */
    getXLS () {
     this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      delete this.exportForm.date;
      let data = {
        baseUrl: "report",
        fileName: `车辆服务统计-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/serviceStatistics/vehicleServiceExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.serviceDataStatistics.clientHeight
      let buttonsHeight = this.$refs.functionButtons.clientHeight
      let formHeight = this.$refs.form.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight = wholeHeight - 16 - buttonsHeight - 16 - formHeight - paginationHeight - 10
    },
    // 提交
    onSubmit (value) {
      this.form = { ...this.form, ...value }
      this.form.currentPage = 1
      this.getDataList()
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getDataList();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.getDataList();
    },
    // 获取列表
    getDataList () {
      queryVehicleDataByList(this.form).then(res => {
        if (res.code === 1000) {
          if(res.data.total>0){
            res.data.list.forEach((item,index)=>{
              item.index = index + 1;
            })
            this.tableData = res.data.list;
            this.total = res.data.total;
          }else{
            this.tableData = [];
            this.total=0;
          }
        }
      })
      this.exportForm = { ...this.form };
    },    
    handleClick (row, column) {
      console.log(111, row, column)
      if (row.index === 1) return
      switch (column.property) {
        case 'operatingAmount':
          this.params = {
            beginTime: this.form.beginTime,
            endTime: this.form.endTime,
            vehicleNo: row.vehicleNo,
            type:1
          }
          this.dialogVisible = true
          break
        case 'signInNum':
          this.params = {
            beginTime: this.form.beginTime,
            endTime: this.form.endTime,
            vehicleNo: row.vehicleNo,
            type: 1//1代表车辆,0代表驾驶员
          }
          this.signVisible = true
          break
        case 'signOutNum':
          this.params = {
            beginTime: this.form.beginTime,
            endTime: this.form.endTime,
            vehicleNo: row.vehicleNo,
            type: 1
          }
          this.signVisible = true
          break

        default:
          break
      }
    },
    /** 数据% */
    formatIndex (row, column, cellValue) {
      return parseInt(cellValue) < parseFloat(cellValue) ? (cellValue).toFixed(2) : cellValue
    },
    formateIndex (number, children) {
      return children ? number : ''
    },
    /** 数据为空默认显示- */
    formatNull (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : cellValue
    },
    /** Money */
    formatMoney (cellValue) {
      return cellValue == null || cellValue === '' ? '-' : `${this.formatIndex(null, null, cellValue)}元`
    }

  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.service-data-statistics {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}
.active {
  color: #059370;
  cursor: pointer;
}

.theme-project-taxi {
  .service-data-statistics {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
